const AppHeader = () => import(/* webpackChunkName: "analysis" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "analysis" */ '@/components/app-footer/index.vue');
const Analysis = () => import(/* webpackChunkName: "analysis" */ '@/features/analysis/index.vue');
const Search = () => import(/* webpackChunkName: "analysis" */ '@/features/analysis/search/index.vue');
const UserAnalysis = () => import(/* webpackChunkName: "analysis" */ '@/features/analysis/user-analysis/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Analysis,
    footer: AppFooter,
  },
  name: 'analysis',
  path: '/analysis',
  redirect: { name: 'analysis.search' },
  children: [{
    component: Search,
    name: 'analysis.search',
    path: 'search',
  }, {
    component: UserAnalysis,
    name: 'analysis.user-analysis',
    path: 'user-analysis/:id?',
  }],
};
