import { createStore } from 'vuex';
import logoLightSvg from '@/assets/Export-logo_Logo-BleuDark.svg';

export default createStore({
  state: {
    logo: logoLightSvg,
  },
  actions: {},
  mutations: {
    setLogo(state, payload) {
      state.logo = payload;
    },
  },
  modules: {},
});
