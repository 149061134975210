const AppHeader = () => import(/* webpackChunkName: "map" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "map" */ '@/components/app-footer/index.vue');
const Map = () => import(/* webpackChunkName: "map" */ '@/features/map/index.vue');
const Preview = () => import(/* webpackChunkName: "map" */ '@/features/map/preview/index.vue');
const Travellers = () => import(/* webpackChunkName: "map" */ '@/features/map/travellers/index.vue');
const Events = () => import(/* webpackChunkName: "map" */ '@/features/map/events/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Map,
    footer: AppFooter,
  },
  name: 'map',
  path: '/map',
  redirect: { name: 'map.preview' },
  children: [{
    component: Preview,
    name: 'map.preview',
    path: 'preview',
  }, {
    component: Travellers,
    name: 'map.travellers',
    path: 'travellers',
  }, {
    component: Events,
    name: 'map.events',
    path: 'events',
  }],
};
