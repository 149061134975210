const AppHeader = () => import(/* webpackChunkName: "profile" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "profile" */ '@/components/app-footer/index.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ '@/features/profile/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Profile,
    footer: AppFooter,
  },
  name: 'profile',
  path: '/profile',
};
