const AppHeader = () => import(/* webpackChunkName: "network" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "network" */ '@/components/app-footer/index.vue');
const Network = () => import(/* webpackChunkName: "network" */ '@/features/network/index.vue');
const Sigma = () => import(/* webpackChunkName: "network" */ '@/features/network/sigma/index.vue');
const Echarts = () => import(/* webpackChunkName: "network" */ '@/features/network/echarts/index.vue');
const Vis = () => import(/* webpackChunkName: "network" */ '@/features/network/vis/index.vue');
const VNetworkGraph = () => import(/* webpackChunkName: "network" */ '@/features/network/v-network-graph/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Network,
    footer: AppFooter,
  },
  name: 'network',
  path: '/network',
  redirect: { name: 'network.v-network-graph' },
  children: [{
    component: Sigma,
    name: 'network.sigma',
    path: 'sigma',
  }, {
    component: Echarts,
    name: 'network.echarts',
    path: 'echarts',
  }, {
    component: Vis,
    name: 'network.vis',
    path: 'vis',
  }, {
    component: VNetworkGraph,
    name: 'network.v-network-graph',
    path: 'v-network-graph',
  }],
};
