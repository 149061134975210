const AppHeader = () => import(/* webpackChunkName: "users" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "users" */ '@/components/app-footer/index.vue');
const Users = () => import(/* webpackChunkName: "users" */ '@/features/users/index.vue');
const Search = () => import(/* webpackChunkName: "users" */ '@/features/users/search/index.vue');
const User = () => import(/* webpackChunkName: "users" */ '@/features/users/user/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Users,
    footer: AppFooter,
  },
  name: 'users',
  path: '/users',
  redirect: { name: 'users.search' },
  children: [{
    component: Search,
    name: 'users.search',
    path: 'search',
  }, {
    component: User,
    name: 'users.user',
    path: 'users',
  }],
};
